import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import LoadingFallback from './components/LoadingFallback';

const Home = lazy(() => import('./components/Home'));
const Login = lazy(() => import('./components/Login'));
const EventList = lazy(() => import('./components/EventList'));
const CreateEventPage = lazy(() => import('./components/CreateEventPage'));
const EventDetails = lazy(() => import('./components/EventDetails'));
const EditEventForm = lazy(() => import('./components/EditEventForm'));
const Auth = lazy(() => import('./components/Auth'));
const Profile = lazy(() => import('./components/Profile'));
const CreateFamilyForm = lazy(() => import('./components/CreateFamilyForm'));
const FamilyDashboard = lazy(() => import('./components/FamilyDashboard'));
const GroupDashboard = lazy(() => import('./components/GroupDashboard'));
const Invitations = lazy(() => import('./components/Invitations'));
const EditGroupPage = lazy(() => import('./components/EditGroupPage'));
const JoinGroupPage = lazy(() => import('./components/JoinGroupPage'));

const App = React.memo(() => {
  const [isMobile, setIsMobile] = useState(() => window.innerWidth < 768);

  useEffect(() => {
    const checkIfMobile = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return (
    <div className={`min-h-screen bg-gray-950 ${isMobile ? 'pb-16' : ''}`}>
      <Navbar />
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/events" element={<EventList />} />
          <Route path="/events/:eventId" element={<EventDetails />} />
          <Route path="/events/edit/:eventId" element={<EditEventForm />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/create-family" element={<CreateFamilyForm />} />
          <Route path="/family/dashboard" element={<FamilyDashboard />} />
          <Route path="/groups" element={<GroupDashboard />} />
          <Route path="/groups/join/:inviteCode" element={<JoinGroupPage />} />
          <Route path="/invitations" element={<Invitations />} />
          <Route path="/events/create" element={<CreateEventPage />} />
          <Route path="/groups/edit/:groupId" element={<EditGroupPage />} />
        </Routes>
      </Suspense>
    </div>
  );
});

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;