// src/firebaseInit.js

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firebaseConfig } from './firebase';

export function initializeFirebase() {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      resolve(user);
    });
  });
}