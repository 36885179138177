import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { FaHome, FaUsers, FaCalendarAlt } from 'react-icons/fa';
import { IoMdAdd } from 'react-icons/io';
import { UserMenu } from './UserMenu'; 
import NotificationBell from './NotificationBell';

export default function Navbar() {
    const [user, setUser] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
        });

        const checkIfMobile = () => setIsMobile(window.innerWidth < 768);
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);

        return () => {
            unsubscribe();
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    const handlePostClick = () => {
        navigate('/events/create');
    };

    const handleLoginClick = () => {
        navigate('/auth?mode=signin');
    };

    return (
        <>
            <nav className="bg-gray-950 border-b border-accent p-4">
                <div className="container mx-auto flex justify-between items-center">
                    <Link to="/" className="text-accent text-xl font-bold">
                        <img src={process.env.PUBLIC_URL + '/logo-h.webp'} alt="Logo" width={128} height={40} />
                    </Link>
                    {!isMobile && user && (
                        <div className="flex items-center space-x-4">
                            <Link 
                                to="/family/dashboard" 
                                className="text-white hover:text-accent transition-colors family-dashboard-link"
                            >
                                Family
                            </Link>
                            <Link 
                                to="/groups" 
                                className="text-white hover:text-accent transition-colors groups-link"
                            >
                                Groups
                            </Link>
                            <Link 
                                to="/events" 
                                className="text-white hover:text-accent transition-colors events-link"
                            >
                                Events
                            </Link>
                        </div>
                    )}
                    <div className="flex items-center space-x-4">
                        {user && <NotificationBell />}
                        {user ? (
                            <UserMenu />
                        ) : (
                            <button
                                onClick={handleLoginClick}
                                className="px-4 py-2 bg-gray-950 text-accent border border-accent rounded-md hover:bg-accent hover:text-black transition-colors duration-300"
                            >
                                Login
                            </button>
                        )}
                    </div>
                </div>
            </nav>
            {isMobile && user && (
                <div className="fixed z-50 w-full h-16 max-w-lg -translate-x-1/2 bg-gray-950 border border-gray-700 bottom-0 left-1/2">
                    <div className="grid h-full max-w-lg grid-cols-5 mx-auto">
                        <Link to="/" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-700 group">
                            <FaHome className="w-6 h-6 mb-1 text-gray-400 group-hover:text-white" />
                            <span className="text-xs text-gray-400 group-hover:text-white">Home</span>
                        </Link>
                        <Link 
                            to="/groups" 
                            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-700 group groups-link"
                        >
                            <FaUsers className="w-6 h-6 mb-1 text-gray-400 group-hover:text-white" />
                            <span className="text-xs text-gray-400 group-hover:text-white">Groups</span>
                        </Link>
                        <button
                            onClick={handlePostClick}
                            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-700 group"
                        >
                            <IoMdAdd className="w-8 h-8 mb-1 text-accent group-hover:text-white" />
                            <span className="text-xs text-accent group-hover:text-white">Post</span>
                        </button>
                        <Link 
                            to="/events" 
                            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-700 group events-link"
                        >
                            <FaCalendarAlt className="w-6 h-6 mb-1 text-gray-400 group-hover:text-white" />
                            <span className="text-xs text-gray-400 group-hover:text-white">Events</span>
                        </Link>
                        <Link 
                            to="/family/dashboard" 
                            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-700 group family-dashboard-link"
                        >
                            <FaUsers className="w-6 h-6 mb-1 text-gray-400 group-hover:text-white" />
                            <span className="text-xs text-gray-400 group-hover:text-white">Family</span>
                        </Link>
                    </div>
                </div>
            )}
        </>
    );
}