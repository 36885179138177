import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

export function UserMenu() {
  const [user, setUser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [installPrompt, setInstallPrompt] = useState(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleBeforeInstallPrompt = (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later
      setInstallPrompt(e);
    };

    const handleAppInstalled = () => {
      // Clear the saved prompt when app is installed
      setInstallPrompt(null);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', handleAppInstalled);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      unsubscribe();
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  if (!user) {
    return null;
  }

  const displayName = user.displayName || user.email || 'User';
  const initials = displayName.charAt(0).toUpperCase();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setIsOpen(false);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleInstallClick = async () => {
    if (!installPrompt) {
      // If installPrompt is null, show manual installation instructions
      const userAgent = navigator.userAgent.toLowerCase();
      let message = '';

      if (userAgent.includes('chrome')) {
        message = 'To install, click the install icon (⊕) in the address bar.';
      } else if (userAgent.includes('firefox')) {
        message = 'To install, click the home icon with a plus (+) in the address bar.';
      } else if (userAgent.includes('safari')) {
        message = 'To install, tap the share icon and select "Add to Home Screen".';
      } else {
        message = 'To install, check your browser settings for an "Add to Home Screen" option.';
      }

      alert(message);
      return;
    }

    try {
      // Show the installation prompt
      const result = await installPrompt.prompt();
      console.log('Install prompt result:', result);
      
      // Wait for the user to respond to the prompt
      const { outcome } = await installPrompt.userChoice;
      console.log('User choice:', outcome);
      
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      
      // Clear the saved prompt - it can't be used twice
      setInstallPrompt(null);
      setIsOpen(false);
    } catch (err) {
      console.error('Error during installation:', err);
      alert('There was an error installing the app. Please try again or install manually from your browser menu.');
    }
  };

  const shouldShowInstallButton = installPrompt || 
    !window.matchMedia('(display-mode: standalone)').matches;

  return (
    <div className="relative" ref={menuRef}>
      <button 
        onClick={() => setIsOpen(!isOpen)} 
        className="flex items-center justify-center text-white hover:bg-gray-950 rounded-full p-2"
      >
        {user.photoURL ? (
          <img 
            src={user.photoURL} 
            alt={displayName} 
            className="rounded-full w-8 h-8"
          />
        ) : (
          <div className="rounded-full bg-gray-900 text-white w-8 h-8 flex items-center justify-center">
            {initials}
          </div>
        )}
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-gray-950 border border-gray-700 rounded-lg shadow-lg">
          <div className="px-4 py-2 border-b border-gray-700">
            <div className="text-sm text-white">{displayName}</div>
            <div className="text-xs text-gray-400">{user.email}</div>
          </div>
          <Link to="/profile" className="block px-4 py-2 text-sm text-white hover:bg-gray-700">Profile</Link>
          <Link to="/family/dashboard" className="block px-4 py-2 text-sm text-white hover:bg-gray-700">Family</Link>
          <Link to="/groups" className="block px-4 py-2 text-sm text-white hover:bg-gray-700">Groups</Link>
          <Link to="/events" className="block px-4 py-2 text-sm text-white hover:bg-gray-700">Events</Link>
          {shouldShowInstallButton && (
            <button 
              onClick={handleInstallClick} 
              className="w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700"
            >
              Install App
            </button>
          )}
          <button onClick={handleSignOut} className="w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700">Sign Out</button>
        </div>
      )}
    </div>
  );
}