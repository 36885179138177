// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initializeFirebase } from './firebaseInit';

const root = ReactDOM.createRoot(document.getElementById('root'));

initializeFirebase().then(() => {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});

serviceWorkerRegistration.register();