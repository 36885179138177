import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, onSnapshot, doc, writeBatch } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { BellIcon } from 'lucide-react';

const NotificationBell = () => {
  const [notifications, setNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const notificationsRef = collection(db, 'notifications');
    const q = query(
      notificationsRef,
      where('isRead', '==', false),
      where('userId', '==', user.uid)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const notificationsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(notificationsData);
    });

    return () => {
      unsubscribe();
    };
  }, [auth, db]);

  const unreadCount = notifications.length;

  const handleNotificationClick = (notification) => {
    console.log('Clicked notification:', notification);
    
    if (notification.linkTo) {
      console.log('Navigating to:', notification.linkTo);
      setTimeout(() => navigate(notification.linkTo), 0);
    } else if (notification.type === 'invitation') {
      console.log('Navigating to /invitations');
      setTimeout(() => navigate('/invitations'), 0);
    } else {
      console.log('No navigation defined for this notification type');
    }

    const notificationRef = doc(db, 'notifications', notification.id);
    writeBatch(db).update(notificationRef, { isRead: true }).commit();

    setIsOpen(false);
  };

  const clearAllNotifications = async () => {
    const batch = writeBatch(db);
    notifications.forEach((notification) => {
      const notificationRef = doc(db, 'notifications', notification.id);
      batch.update(notificationRef, { isRead: true });
    });
    await batch.commit();
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative inline-flex items-center p-3 text-sm font-medium text-accent border border-accent rounded-lg focus:outline-none focus:ring-2 focus:ring-accent transition-colors"
      >
        <BellIcon className="w-6 h-6" />
        {unreadCount > 0 && (
          <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-black bg-accent border-2 border-black rounded-full -top-2 -right-2">
            {unreadCount}
          </div>
        )}
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-80 bg-gray-950 border border-accent rounded-md shadow-lg overflow-hidden z-20">
          <div className="py-2">
            {notifications.length === 0 ? (
              <div className="px-4 py-2 text-sm text-accent">No notifications</div>
            ) : (
              <>
                {notifications.map((notification) => (
                  <div
                    key={notification.id}
                    onClick={(e) => {
                      e.preventDefault();
                      handleNotificationClick(notification);
                    }}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-800 transition-colors"
                  >
                    <p className="text-sm text-white">{notification.content}</p>
                    <p className="text-xs text-accent">
                      {notification.createdAt?.toDate().toLocaleString()}
                    </p>
                  </div>
                ))}
                <button
                  onClick={clearAllNotifications}
                  className="w-full px-4 py-2 mt-2 text-sm text-accent bg-gray-950 hover:bg-gray-700 transition-colors"
                >
                  Clear All Notifications
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationBell;